import React, { useContext, useEffect } from 'react';
import Page from 'layouts/Page';
import { Paper } from '@mui/material';
import Filtros from 'components/BuscadorSolicitud/filtros';
import TablaSolicitudes from 'components/tablaSolicitudes/tablaSolicitudes';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import { PERMISOS } from 'constants/constants';
import CepSelector from 'components/CepSelector';
import { fetchCepsDisponibles } from '../redux/slices/solicitudSlice';

const classes = {
    paper: {
        marginBottom: "10px",
        paddingTop: "10px",
        paddingBottom:"10px",
        paddingLeft: "44px",
        paddingRight: "44px",
        display: "flex",
        gap: 1,
        justifyContent: "space-between"
    },
    table: {
        mt: 6
    }
}

const  Home = () => {
    // const solicitudSlice = useSelector(state => state.solicitud);
    // const dispatchSolicitud = useDispatch();
    // useEffect(() => {
        // dispatchSolicitud(fetchCepsDisponibles());
    // }, []);
    
    return (
        <Page>
            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                <CepSelector />
            </Restricted>
            <Paper>
               <Filtros/>
            </Paper>
            <Paper sx={classes.table}>
                <TablaSolicitudes/>
            </Paper>
        </Page>
    )
}

export default Home
