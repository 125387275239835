import React, {useContext, useEffect, useState} from 'react'
import { Button, Paper, Fab, Tooltip} from '@mui/material'
import {CotizacionContext } from 'context/cotizacion/cotizacionContext'
import { resetIntegracion, setNuevaNorma } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddLinkIcon from '@mui/icons-material/AddLink';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import InputNormaMotivo from 'components/inputNormasMotivos'
import { Box } from '@mui/system'
import SaveIcon from '@mui/icons-material/Save';
import LabelIntegracion from 'components/labelIntegracion';

// Styles
import 'scss/components/paper-input-normas.scss';
import { PERMISOS, TIPO_ESTADO } from 'constants/constants';
 
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import { useSelector } from 'react-redux';

const PaperNorma = ({ handleModal, setModoEdicion, modoEdicion }) => {
    //global context
    const globalSlice = useSelector(state => state.global)
    const {listaNormas} = globalSlice
    const solicitudSlice = useSelector(state => state.solicitud);
    const {listaCotizaciones} = solicitudSlice
    //contexto cotizacion
    const {state, dispatch} = useContext(CotizacionContext);
    const {inputNormasMotivos, json, inputIntegracion, inputDatosCotizacion, estadoCotizacion, estadoCarga} = state;

    const [noEliminar, setNoEliminar] = useState(true)
    const [limiteNormas, setLimiteNormas] = useState(false)
    const [integracion, setIntegracion] = useState(true)
    const [habilitadoGuardar, setHabilitadoGuardar] = useState(false)
    const normasIntegradas = json.normas.filter(n => n.integrada ===true)

    useEffect(() => {

        if(inputNormasMotivos.length > 1){
            setNoEliminar(false)
        }
        else{
            setNoEliminar(true)
        }

        if(inputNormasMotivos.length < listaNormas.length)
        {
            setLimiteNormas(false)
        }
        else{
            setLimiteNormas(true)
        }
        if(inputNormasMotivos.filter(i => i.integrada === true && i.norma !== '').length>1){
            setIntegracion(false)
        }
        else{
            setIntegracion(true)
        }
        
        if(inputNormasMotivos.every(i => i.integrada === false)){
            setHabilitadoGuardar(false)
        }
        else{
            if(inputIntegracion.motivosOrganizacion.length>0)
            {
                setHabilitadoGuardar(false)
            }
            else{
                setHabilitadoGuardar(true)
            }
        }
    }, [inputNormasMotivos, inputIntegracion, listaNormas, inputDatosCotizacion.nombre])

    const handleAgregarNorma = () => {
        const nuevaNorma = { id:0, norma:'', motivo:'', nombre:'', integrada:false, 
                                vencimiento:new Date().toISOString(), 
                                cambioVersion:false, 
                                transferencia:false, 
                                ampliacion:false, 
                                reduccion:false,
                                numero:'', 
                                ente:1,
                                cluster:0,
                                consumoEnergiaAnual: 0,
                                fuentesDeEnergia: 0,
                                usosDeEnergia: 0,
                                nuevaNorma: true
                            }
        
        dispatch(setNuevaNorma(nuevaNorma))
        setNoEliminar(false)
    }
    const handleDeleteIntegracion = () => {
        dispatch(resetIntegracion())
    }
    const handleBotonEditar = () => {
        setModoEdicion(true)
    }

    return (
        <Paper className="my_paper" elevation={5} sx={{paddingBottom:"20px", position:"relative"}}  >
                {
                    inputNormasMotivos.map((itemNormaMotivo, index) =>
                        <InputNormaMotivo 
                            key={"inputnormamotivo"+index}
                            index={index}
                            noEliminar={noEliminar}
                            setLimiteNormas={setLimiteNormas}
                            modoEdicion={modoEdicion}
                            tipo={"GESTION"}
                        />
                    )
                }
                { json.id === '' || modoEdicion ?
                    <>
                        {   inputIntegracion.motivosOrganizacion.length>0 &&
                            <Box className="integration-detail">
                                <Box className="norms">
                                    {listaNormas.filter(l => inputNormasMotivos.find(i=> i.norma === l.id)).map(n => n.numero).join('-')}
                                </Box>
                                {
                                    inputNormasMotivos.length > 1 &&
                                    <Box className="percentage">
                                    {' - '+inputIntegracion.porcentajeReduccion*100+'%'}
                                    </Box>
                                }
                                <Box className="motive">
                                    {inputIntegracion.motivosOrganizacion.map(m => m.nombre).join(', ')}
                                </Box>
                                <Box className="actions">
                                    <IconButton variant="edit" onClick={handleModal}>
                                        <EditIcon/>
                                    </IconButton>
                                    {estadoCotizacion.estado === TIPO_ESTADO.BORRADOR &&
                                    <IconButton variant="delete" onClick={handleDeleteIntegracion}>
                                    <DeleteIcon/>
                                    </IconButton>
                                    }
                                </Box>                      
                            </Box>
                        }
                        <Box display="flex" flexDirection="row" justifyContent={ estadoCotizacion.estado === TIPO_ESTADO.BORRADOR?"space-between":"flex-end" } sx={{padding:"10px"}}>
                            {
                                estadoCotizacion.estado === TIPO_ESTADO.BORRADOR &&
                                <>
                                <Tooltip title={integracion?"Debe seleccionar 2 o mas normas para poder integrar":""}>
                                    <Button variant="outlined" color="integracion" startIcon={<AddLinkIcon/>} onClick={integracion?null:handleModal}>Integrar Normas</Button>
                                </Tooltip>
                                <Button variant="contained" startIcon={<AddIcon/>} color="primary" disabled={limiteNormas} onClick={handleAgregarNorma}>Agregar Norma</Button>
                                </>
                            }
                            <Tooltip title={habilitadoGuardar?"Debe integrar 2 o mas normas para poder guardar":""}>
                                <Button variant="contained" color="primary" startIcon={<SaveIcon/>} type={habilitadoGuardar?"button":"submit"} disabled={listaCotizaciones.some(c => c.nombre.toUpperCase() === inputDatosCotizacion.nombre.toUpperCase())&& json.id === ''}>Guardar</Button>
                            </Tooltip>
                        </Box>
                    </>
                    :   
                    <>
                        {inputIntegracion.motivosOrganizacion.length>0 &&
                            <LabelIntegracion normasIntegradas={normasIntegradas}/>
                        }
                    </>
                }
            {
               ( estadoCotizacion.permiteEdicion || estadoCotizacion.estado === TIPO_ESTADO.ENVIADA) && json.id !== '' && !modoEdicion &&
               <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                <Fab 
                color="primary" 
                aria-label="add" 
                sx={{position:"absolute", right:"-70px", top:"22%"}}
                onClick={handleBotonEditar}
                disabled={estadoCarga.guardando}>
                    <EditIcon  />
                </Fab>
                </Restricted>
            }
        </Paper>
    )
}

export default PaperNorma
