import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import { Typography } from '@mui/material';
import { useContext } from 'react';
 
import { searchOrganizaciones } from 'services/Organizacion/organizacionService';
import { useSelector } from 'react-redux';


export default function InputOrganizaciones({ value, handleOrganizacion, required, onOrganizacionesChange, multiple }) {
    multiple = multiple != undefined ? multiple : true;
    
    const [uncontrolledValue, setUncontrollerValue] = React.useState(multiple ? [] : undefined);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);

    const solicitudSlice = useSelector(state => state.solicitud);
    const {organizaciones} = solicitudSlice

    
    
    React.useEffect(() => {

        setUncontrollerValue(organizaciones.map(org => {
            if(org.label){
                return org;
            }else{
                return (
                    {
                        id: org.id,
                        label: org.razonSocialAFIP,
                        nombre: org.razonSocialAFIP,
                        cuit: org.cuit
                    }
                );
            }
        }))
    }, [organizaciones])

    const getValue = () => {
        if(value != undefined){
            return value;
        }else{
            return uncontrolledValue;
        }
    }

    const searchEmpresa = async (request, callback) => {

        try {
            let organizaciones = await searchOrganizaciones(request.input);
            organizaciones = organizaciones
                .map(org => (
                    {
                        id: org.id,
                        label: org.razonSocialAFIP,
                        nombre: org.razonSocialAFIP,
                        cuit: org.cuit
                    }
                ))
            return callback(organizaciones);
        } catch (error) {
            callback([]);
        }

    }

    const fetch = React.useMemo(() => debounce(searchEmpresa,400),[]);

    React.useEffect(() => {
        if (inputValue === '') {
            setOptions(getValue() ? [getValue()] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            let newOptions = [];

            if (getValue()) {
                newOptions = [getValue()];
            }

            if (results) {
                newOptions = [...newOptions, ...results];
            }
            setOptions(newOptions);
        });
    }, [value, uncontrolledValue, inputValue, fetch]);

    const getOptions = () => {
        if(multiple){
            return options.filter(option => !getValue().some(v => v.id === option.id));
        }else{
            return options.filter(option => !getValue() || getValue().id !== option.id);
        }
    }
    console.log(getValue());
    return (
        <Autocomplete
            id="search-orgs"
            
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.label
            }
            options={getOptions()}
            autoComplete
            includeInputInList
            multiple={multiple}
            fullWidth
            value={getValue()}
            noOptionsText="No se encontraron resultados"
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setUncontrollerValue(newValue);
                onOrganizacionesChange && onOrganizacionesChange(newValue);
                handleOrganizacion && handleOrganizacion(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField required={required ? getValue() == null || getValue() == undefined || getValue().length == 0 : false}  {...params} label={multiple ? "Organizaciones": "Organización"} fullWidth />
            )}
            renderOption={(props, option) => {

                return (
                    <li {...props}>
                        <Typography variant="body2">
                            {option.label}
                        </Typography>
                    </li>
                );
            }}
        />

    );
}