import { useContext, useEffect, useState } from 'react';
import { AppBar, Box, Button, Paper, Tab, Tabs, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
// import { setNuevoTurno, setTurnos } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
 
// import AlimentosTurnosInput from './input';
// import AlimentosTurnosSelected from './selected';
import { ConstructionOutlined } from '@mui/icons-material';
import TabPanel from 'components/tabPanel';
import { de } from 'date-fns/locale';
import AccordionNormaAlimentos from 'components/AccordionNormaAlimentos';
import { makeStyles } from '@mui/styles';
import TabPropuestaEconomica from 'components/TabPropuestaEconomica';
import PaperAuditoria from 'components/PaperAuditorias';
import TabVisitas from 'components/TabVisitas';
import TabDiasAuditorSitios from 'components/TabDiasAuditorSitio';
import TabSitios from 'components/alimentos/TabSitios';
function PaperCalculoAlimentos() {

    const {state} = useContext(CotizacionContext);
    const { json } = state;

    const [tab, setTab] = useState(0)

    const handleChangeTab = (event, newValue) => {
        setTab(newValue)
    }
    const useStyles = makeStyles((theme) => ({
        paper: {
            padding: theme.spacing(3),
            margin: theme.spacing(3),
        },
        fixedButton:{
            margin:0,
            top:"auto",
            left:50,
            bottom:20,
            right:"auto",
            position:"fixed"
        }

    }));
    const classes = useStyles();

    let sitioNormas = []
    let grupoGeneral = null
    let sitioGeneral = null
    if(json.id === ""){

    }else{
        grupoGeneral = json.gruposDeSitios.find(g => g.tipo === "GENERAL")
        sitioGeneral = grupoGeneral.sitios[0]
        sitioNormas = sitioGeneral.normas
    }

    return (
        <Paper className="my_paper" elevation={5} sx={{position:"relative"}}>
            <Box>
                <AppBar className={classes.appbar} position='relative' color='default'>
                    <Tabs value={tab} onChange={handleChangeTab}>
                        {
                            json.usaMultisitios ?
                            <Tab label="MULTISITIOS"/>
                            :
                            <Tab label="CALCULO DIAS AUDITOR"/>
                        }
                        <Tab key={"tab1"} label='PROPUESTA ECONOMICA'/>
                    </Tabs>
                </AppBar>
                {
                    json.usaMultisitios ?
                    <TabPanel key={"tab1"} value={tab} index={0}>
                          {/* <TabVisitas/> */}
                        <TabDiasAuditorSitios></TabDiasAuditorSitios>
                    </TabPanel>
                    :
                    <TabPanel key={"tab0"} value={tab} index={0}>
                    {
                    sitioNormas.sort((a, b) => a.cotizacionNorma.norma.jerarquiaAlimentos - b.cotizacionNorma.norma.jerarquiaAlimentos).map((norma, index) =>
                    <AccordionNormaAlimentos norma={norma} index={index} idGrupo={grupoGeneral.id} idSitio={sitioGeneral.id}></AccordionNormaAlimentos>
                    )
                    }
                    </TabPanel>
                }
                
                
                <TabPanel key={"tab2"} value={tab} index={1}>
                    <TabPropuestaEconomica></TabPropuestaEconomica>
                </TabPanel>
            </Box>
        </Paper>
    )
}

export default PaperCalculoAlimentos