import { useContext, useState } from 'react';
import { Button, Paper, Box } from '@mui/material';
import { postCotizacionAlimento } from 'services/CotizacionAlimentos';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
 
import AlimentosDatosCotizacion from 'components/alimentos/datosCotizacion';
import AlimentosNaces from 'components/alimentos/naces';
import AlimentosNormas from 'components/alimentos/normas';
import AlimentosTurnos from 'components/alimentos/turnos';
import { useHistory, useParams } from 'react-router-dom';
import { setDatosCotizacion, setEstadoCotizacion, setJson, setNormaMotivo, updateCotizacion, updateCotizacionAlimentos } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import { RUTAS } from 'constants/routes';
import { parseNormas } from 'utlis/normasParser';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from 'redux/slices/globalSlice';

const AlimentosFormCotizacion = ({ onToggleGuardar , edit}) => {
   const dispatchRedux = useDispatch()
   const { state, dispatch } = useContext(CotizacionContext);
   const solicitudSlice  = useSelector(state => state.solicitud);

   const { inputNormasMotivos, inputDatosCotizacion, turnos, inputCodigosNACESUBNACES, estadoCarga, json, multisitios } = state;
   const { idSolicitud, numero, personalEfectivo } = solicitudSlice

   let turnoSeleccionados = turnos

   const history = useHistory();

   const navigateToCotizacion = (idCotizacion) => {
      history.push(
          RUTAS.COTIZACION
          .replace(":idSolicitud", idSolicitud)
          .replace(":idCotizacion", idCotizacion));
    }

   const handleGuardarCotizacionAlimentos = async (e) => {
      e.preventDefault();
      const date = new Date()
      
      const bodyNorma = inputNormasMotivos.map(norma => {
         const data = {
            "id": norma.id,
            "norma": norma.norma,
            "motivo": norma.motivo,
            "numeroMantenimiento": norma.numero === "" ? null : norma.numero,
            "cantidadMantenimientos": norma.cantidadMantenimientos,
            "esReduccionDeAlcance": false,
            "esAmpliacionDeAlcance": false,
            "esCambioDeVersion": false,
            "esTransferencia": false,
            "fechaVencimientoCertificado": null,
            "ente": null,
         }
         
         return data
      })
      const bodyTurnos = turnoSeleccionados.map((turno, index) => {
         const data = {
            "id": turno.id,
            "descripcion": turno.descripcion,
            "horarioInicio": turno.horarioInicio,
            "horarioFin": turno.horarioFin,
            "personal": turno.personal
         }
         return data
      })

      const boodyNaces = inputCodigosNACESUBNACES.map(nace => {
         
         const data = {
            "id": nace.id,           
            "subNaces": null // VERIFICAR CAMPO - subCategoria
         }

         return data
      })

      const bodyCotizacion = {
         "id": numero,
         "idSolicitud": idSolicitud,
         "tipo": "ALIMENTOS",
         "fechaModificacion": date.toISOString(),
         "nombre": inputDatosCotizacion.nombre,
         "codigo": null, // VERIFICAR CAMPO
         "cantidadPersonal": personalEfectivo,
         "categoria": "A",
         "normas": bodyNorma,
         "cantidadEstudiosHACCP":inputDatosCotizacion.cantidadEstudiosHaccp,
         "estado": {  // VERIFICAR CAMPO
            "estado": "Borrador", // VERIFICAR CAMPO
            "posiblesTransiciones": null,
            "permiteEdicion": true // VERIFICAR CAMPO
         },
         "motivoRechazo": "string", // VERIFICAR CAMPO
         "nacEs": boodyNaces,
         "turnos": bodyTurnos,
         "usaMultisitios": multisitios,
      }
      if(json.id === "" || json.id === null || json.id === undefined)
      {
         postCotizacionAlimento(bodyCotizacion)
         .then(response => {
             let estadoAux = {...response.estado}
             estadoAux.posiblesTransiciones = estadoAux.posiblesTransiciones.concat(estadoAux.estado)
             dispatch(setEstadoCotizacion(estadoAux))
             const normasMotivos = parseNormas(response.normas)
             dispatch(setNormaMotivo(normasMotivos))
 
             const info = {
                 nombre:response.nombre,
                 seguimientos:response.cantidadMantenimientos, 
             codigo:response.codigo,
             }
 
             dispatch(setDatosCotizacion(info))
 
             dispatch(setJson(response))
         
             navigateToCotizacion(response.id);
         })
         .catch(error => {
            dispatchRedux(setError({estado:true, tipo:error.status, descripcion:error.data.details?error.data.details:"sin detalles"}))
             console.error("ERROR POST COTIZACION:", error)
             console.error("body",data)
         })
         }
     else{
      
      let data = {...bodyCotizacion, id:json.id}
         updateCotizacionAlimentos(dispatch, data);
     }
   }
   
   return (
      <>
         <form onSubmit={handleGuardarCotizacionAlimentos}>
            <AlimentosDatosCotizacion 
               guardarCotizacion={handleGuardarCotizacionAlimentos}
            />
            <AlimentosNormas
               guardarCotizacion={handleGuardarCotizacionAlimentos}
            />
            <AlimentosTurnos 
               guardarCotizacion={handleGuardarCotizacionAlimentos}
            />
            <AlimentosNaces
               guardarCotizacion={handleGuardarCotizacionAlimentos}
            />

            {
               json.id === 0 || json.id ==="" &&
               <Paper elevation={5} sx={{ height: '100px', position: "relative", marginTop: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
               <Button variant="contained"
                  color="primary"
                  size="large"
                  type='submit'
               >
                  Guardar
               </Button>
               </Paper>
            }
         </form>
      </>
   )
}

export default AlimentosFormCotizacion;
//  {/* {
//             json.id == 0&&
//             <Paper elevation={5} sx={{ height: '100px', position: "relative", marginTop: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
//             <Button variant="contained"
//                color="primary"
//                size="large"
//                type='submit'
//             >
//                Guardar
//             </Button>
//          </Paper>
//          } */}
/* <AlertaPopup propsAlert={propsAlert} setProps={setPropsAlert}/> */