import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import { actualizarServicio, borrarServicio, nuevoServicio, guardarNuevaCotizacionProducto, actualizarCotizacionProducto, actualizarServicios } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
 
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
 

// Styles
import 'scss/components/paper-input-normas.scss';
import InputServicios from 'components/InputServicios';
import { useHistory } from 'react-router-dom'
import Restricted from 'components/Restricted'
import { RESTRICT_METHOD } from 'components/Restricted'
import { PERMISOS, TIPO_ESTADO } from 'constants/constants'
import { useDispatch, useSelector } from 'react-redux';

const PaperServicios = ({ handleModal, setModoEdicion, modoEdicion }) => {
    //global context
    const [editarServicios, setEditarServicios] = useState(false);
    const solicitudSlice = useSelector(state => state.solicitud);
    const dispatchRedux = useDispatch()
    const globalSlice = useSelector(state => state.global)
    const { listaServicios } = globalSlice
    const { state, dispatch } = useContext(CotizacionContext);
    const { json: cotizacion, estadoCotizacion, estadoCarga } = state;
    const esCotizacionNueva = cotizacion.id === null || cotizacion.id === undefined || cotizacion.id === "";
    const serviciosActuales = cotizacion.servicios;
    
    const [serviciosBackup, setServiciosBackup] = useState(serviciosActuales);

    const history = useHistory();

    const navigateToCotizacion = useCallback((idCotizacion) => {
        history.push(`/solicitud/${solicitudSlice.idSolicitud}/cotizacion/${idCotizacion}`);
    }, [solicitudSlice, history, cotizacion]);

    function handleEditarButton(){
        setServiciosBackup(JSON.stringify(serviciosActuales));
        setEditarServicios(true);
    }

    function handleCancelarButton(){
        dispatch(actualizarServicios(JSON.parse(serviciosBackup)));
        setEditarServicios(false);
    }

    function guardarCotizacion(event){

        event.preventDefault();

        if(esCotizacionNueva){
            guardarNuevaCotizacionProducto(dispatch,dispatchRedux, {...cotizacion, idSolicitud: solicitudSlice.idSolicitud})
            .then((cotizacion) => navigateToCotizacion(cotizacion.id))
        }else{
            actualizarCotizacionProducto(dispatch,dispatchRedux, {...cotizacion, idSolicitud: solicitudSlice.idSolicitud})
            .then(() => {  setEditarServicios(false)})
            .catch(() => {});
        }
    }

    function onChangeServicio(servicio, index) {
        dispatch(actualizarServicio(index, servicio));
    }

    return (
        <Paper
            className={["my_paper", "paper-input-servicios"]}
            elevation={5}
            sx={{ paddingBottom: "20px", position: "relative" }}>
            <Typography variant="h6" className="paper-input-servicios-title">Servicios</Typography>
            <form onSubmit={guardarCotizacion}>
                {
                    serviciosActuales.map((sa, index) =>
                        <InputServicios
                            modoEdicion={editarServicios || esCotizacionNueva}
                            key={"input-servicios-" + sa.id}
                            servicio={sa}
                            serviciosSeleccionados={serviciosActuales}
                            servicios={listaServicios}
                            onDelete={() => dispatch(borrarServicio(index))}
                            onChange={(servicio) => onChangeServicio(servicio, index)} />
                    )
                }
                <Box className='paper-input-buttons-wrapper'>
                    
                    {(!esCotizacionNueva && !editarServicios && estadoCotizacion.estado === TIPO_ESTADO.BORRADOR) &&
                        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                            <Button
                                startIcon={<EditIcon />}
                                variant='contained'
                                onClick={handleEditarButton}>
                                Editar servicios
                            </Button>
                        </Restricted>
                    }
                    {(esCotizacionNueva || editarServicios) &&
                        <Button
                            variant='contained'
                            disabled={estadoCarga.guardando}
                            onClick={() => dispatch(nuevoServicio())}>
                            Agregar servicio
                        </Button>
                    }
                    <Box>
                        {
                            editarServicios &&
                            <Button
                                sx={{ marginRight: "10px"}}
                                variant="contained" 
                                color="secondary" 
                                disabled={estadoCarga.guardando}
                                onClick={handleCancelarButton}>
                                Cancelar
                            </Button>
                        }
                        {(esCotizacionNueva || editarServicios) &&
                            <Button
                                type='submit'
                                disabled={estadoCarga.guardando}
                                startIcon={<SaveIcon />}
                                variant='contained'>
                                {/* onClick={guardarCotizacion}> */}
                                Guardar
                            </Button>
                        }
                    </Box>
                </Box>
            </form>
        </Paper>
    );
}

export default PaperServicios