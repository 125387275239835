import { TIPO_SOLICITUD_ALIMENTOS, TIPO_SOLICITUD_FORMACION, TIPO_SOLICITUD_GESTION, TIPO_SOLICITUD_PRODUCTO } from "constants/constants";
 
import CotizacionAlimentos from "pages/CotizacionAlimentos";
import CotizacionFormacion from "pages/CotizacionFormacion";
import CotizacionGestion from "pages/CotizacionGestion";
import CotizacionProducto from "pages/CotizacionProducto";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { CircularProgress, Typography, Box } from '@mui/material';
import LoadingCotizacion from "components/LoadingCotizacion";

function CotizacionBasePage(props) {

    const solicitudSlice = useSelector(state => state.solicitud);
    const tipoSolicitud = solicitudSlice.tipo;

    const globalSlice = useSelector(state => state.global);

    const loadingNacesIafs = globalSlice.listaCodigosIAF.length === 0 && globalSlice.listaCodigosNace.length === 0;

    if(loadingNacesIafs){
        return <LoadingCotizacion />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_GESTION){
        return <CotizacionGestion {...props} />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_ALIMENTOS){
        return <CotizacionAlimentos {...props} />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_PRODUCTO ){
        return <CotizacionProducto {...props} />;
    }
    
    if(tipoSolicitud === TIPO_SOLICITUD_FORMACION){
        return <CotizacionFormacion {...props} />;
    }

    return <LoadingCotizacion />;
}


export default CotizacionBasePage;