import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { putDataAsync } from 'services/httpRequest';
// Define tu estado inicial
const initialState = {
    inProgress: false,
    error: null,
};

export const editarSolicitud = createAsyncThunk(
    'editar_solicitud',
    async (data, thunkAPI) => {
        try {
            const response = await putDataAsync('Solicitudes/', data);
            if (response.status === 200 || response.status === 201) {
                return response.data
            } else {
                throw response
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);


export const editarSolicitudSlice = createSlice({
    name: 'editar_solicitud',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(editarSolicitud.pending, (state) => {
                state.inProgress = true;
                state.error = null;
            })
            .addCase(editarSolicitud.fulfilled, (state, action) => {
                state.inProgress = false;
                state.error = null;
            })
            .addCase(editarSolicitud.rejected, (state, action) => {
                state.inProgress = false;
                state.error = action.payload;
            });
    },
});

export const {

} = editarSolicitudSlice.actions;

export default editarSolicitudSlice.reducer;