import React, { useContext, useEffect, useState } from 'react'
import { Button} from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid';
 
import { buscarPagina, setCep, setSolicitud } from 'redux/slices/solicitudSlice';
import { useHistory } from 'react-router';
import { RUTAS } from 'constants/routes';
import { TIPO_SOLICITUD_ALIMENTOS } from 'constants/constants';
import { getSolicitudById } from 'services/Solicitud';
import { format,  } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

const TablaSolicitudes = () => {

    const solicitudSlice = useSelector(state => state.solicitud);
    const dispatchSolicitud = useDispatch();
    const {cargando, paginas, filtros} = solicitudSlice
    const [pagina, setPagina] = useState([])
    const [numeroPagina, setNumeroPagina] = useState(1)

    useEffect(() => {
        if(paginas.paginas){
            let paginaSelecionada = paginas.paginas.filter(p => p.numero === numeroPagina).map(s => s.solicitudes).flat()
            setPagina(paginaSelecionada) 
        }
    }, [paginas, numeroPagina])
    
const columns = [
    {
        field:'numero', 
        headerName:'Numero', 
        width:83, 
        sortable:false
    },
    {
        field:'anio', 
        headerName:"Año", 
        width:60, 
        sortable:false
    },
    {
        field:'usuario', 
        headerName:'Elaborado', 
        width:97,
        sortable:false
    },
    {
        field:'organizaciones', 
        headerName: 'Organizaciones',
        width:190,
        sortable:false,
        valueGetter:(params) => params.row.organizaciones.map(o => o.nombre).join(', ')
    },
    {
        field:'cotizaciones',
        headerName:"Cotizaciones",
        width:118,
        sortable:false,
        valueGetter:(params) => params.row.cotizaciones.length
    },
    {
        field:'tipo',
        headerName:"Tipo",
        width:118,
        sortable:false,
        valueGetter:(params) => params.row.tipo
    },
    {
        field:'fechaModificacion',
        headerName:"Fecha Modificacion",
        width:158,
        sortable:false,
        valueGetter:(params) => {
            const dia = new Date(params.row.fechaModificacion)
            return format(dia, 'dd/MM/yyyy')
        }
    },
    {
        field: 'estado',
        headerName:'Estado',
        width:170,
        sortable:false,
        valueGetter:(params) => params.row.estado.estado
    },
    {
        field: 'Acciones',
        headerName: '',
        width: 150,
        sortable:false,
        renderCell: (params) => (
        <strong>
            <Button
            variant="contained"
            color='primary'
            size='small'
            onClick={event => handleClickSolicitud(event,params)}
            >
            ABRIR   
            </Button>
        </strong>
        )
    }
]

    const history = useHistory()

    const buildListaIAFNACE = (naces) => {
        const aux = naces.map(function(item){
            return {
                CodigoIaf: {
                    id: item.iaf.id,
                    codigo: item.iaf.codigo,
                    descripcion: item.iaf.descripcion
                },
                CodigoNace: {
                    id: item.id,
                    codigo: item.codigo,
                    descripcion: item.descripcion
                }
            }
        })
        return aux;
    }

    const handleClickSolicitud = (event, params) => {
        const tipo = params.row.tipo;

        getSolicitudById(params.id)
            .then(response => {
                dispatchSolicitud(setCep(response.cep))
                dispatchSolicitud(setSolicitud({...response, estado: response.estado.estado}));
                
            })
            .catch(error => {
                console.error("ERROR GET SOLICITUD BY ID", error)
            })
            
        // if (tipo === TIPO_SOLICITUD_ALIMENTOS) {
        //     history.push(RUTAS.ALIMENTOS_SOLICITUD.replace(':idSolicitud', params.id));
        // } else {
            history.push(RUTAS.SOLICITUD.replace(':idSolicitud', params.id));
        // }   

    }
 
    const handlePageChange = (numero) => {
        const numeroActula = filtros.numeroDePagina
        let tengoPagina = false
        if(numeroActula>numero){
            tengoPagina = paginas.paginas.some(p => p.numero === numero)
            if(tengoPagina){
                setNumeroPagina(numero+1)
            }
            else{
                dispatchSolicitud(buscarPagina({...filtros, numeroDePagina: numero}))
                // buscarPagina(dispatchSolicitud, {...filtros, numeroDePagina: numero})
                setNumeroPagina(numero+1)
            }   
        }
        else{
            tengoPagina = paginas.paginas.some(p => p.numero === numero+2)
            if(tengoPagina){
                setNumeroPagina(numero+1)
            }
            else{
                dispatchSolicitud(buscarPagina({...filtros, numeroDePagina: numero+1}))
                // buscarPagina(dispatchSolicitud, {...filtros, numeroDePagina: numero+1})
                setNumeroPagina(numero+1)
            }   
        }
    }
    const handlePageSize = (newPageSize) =>{
        dispatchSolicitud(buscarPagina({...filtros, filasPorPagina: newPageSize, numeroDePagina: 1}))
        // buscarPagina(dispatchSolicitud, {...filtros, filasPorPagina: newPageSize, numeroDePagina: 1})
    }

    return (
    <Box display="flex" sx={{height:"400px", width:"100%",paddingTop:"24px", paddingBottom:"24px"}}>
        <DataGrid
            disableSelectionOnClick
            loading={cargando}
            rows={pagina}
            columns={columns}
            onPageChange={handlePageChange}
            pageSize={filtros.filasPorPagina}
            rowCount={paginas.totalItems}
            onPageSizeChange={(newPageSize) => handlePageSize(newPageSize)}
            paginationMode="server"
            disableColumnFilter
            disableColumnMenu
        />
    </Box>
    )
}

export default TablaSolicitudes
