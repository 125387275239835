import React, { useContext, useEffect, useState, } from 'react'
import { Grid, Typography, Table, TableRow, TableContainer, TableHead, TableBody, TableCell, Button, Paper, Chip, Tooltip, Drawer, Tab, Skeleton, CircularProgress } from '@mui/material'

import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import { resetCotizacion, resetIAFNACE, resetEstadoCotizacion, resetIntegracion, resetNormas, duplicarCotizacion, resetTurnos } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Restricted, { RESTRICT_METHOD } from 'components/Restricted'
import { PERMISOS, TIPO_ESTADO, TIPO_SOLICITUD_FORMACION, TIPO_SOLICITUD_PRODUCTO } from 'constants/constants'
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Box } from '@mui/system'
import ResumenSolicitud from 'components/ResumenSolicitud'
import { getCotizacionById } from 'services/Cotizacion'
import ResumenCotizacion from 'components/ResumenCotizacion'
import { useDispatch, useSelector } from 'react-redux'

const PaperListaCotizacion = (props) => {

    const history = useHistory();

    const solicitudSlice = useSelector(state => state.solicitud);

    const { state, dispatch } = useContext(CotizacionContext)
    let { listaCotizaciones } = solicitudSlice;
    const { estadoCarga } = state

    const listaCotizacionSort = structuredClone(listaCotizaciones)

    listaCotizacionSort.sort((a, b) => (a.fechaModificacion < b.fechaModificacion) ? 1 : -1)

    const navigateToNuevaCotizacion = () => {
        history.push(`/solicitud/${solicitudSlice.idSolicitud}/nuevacotizacion`);
    }

    const handleAgregarCotizacion = () => {
        navigateToNuevaCotizacion();

    }

    const handleOpenCotizacion = (id) => {
        history.push(`/solicitud/${solicitudSlice.idSolicitud}/cotizacion/${id}`)
    }

    const handleDuplicate = (id) => {

        duplicarCotizacion(dispatch, id, handleOpenCotizacion)
    }

    const handleOpenCotizacionPropuesta = (idCotizacion, idPropuesta) => {
        history.push(`/solicitud/${solicitudSlice.idSolicitud}/cotizacion/${idCotizacion}/propuesta/${idPropuesta}`)
    }

    useEffect(() => {
        dispatch(resetCotizacion());
        dispatch(resetIntegracion());
        dispatch(resetIAFNACE());
        dispatch(resetEstadoCotizacion());
        dispatch(resetNormas());
        dispatch(resetTurnos())
    }, [])

    const disabledDuplicar = (item) => {

        if (solicitudSlice.tipo === TIPO_SOLICITUD_PRODUCTO ||
            solicitudSlice.tipo === TIPO_SOLICITUD_FORMACION) {
            return false
        }

        if (item.normas === null) {
            return true
        } else {
            if (estadoCarga.guardando) {
                return true
            } else {
                if (solicitudSlice.tipo === "ALIMENTOS") {
                    return true
                } else {
                    return false
                }
            }
        }
    }


    const getColorEstado = (estado) => {
        switch (estado) {
            case TIPO_ESTADO.ACEPTADA: {
                return 'success';
            }
            case TIPO_ESTADO.ENVIADA: {
                return 'warning';
            }
            case TIPO_ESTADO.DESCARTADA: {
                return 'error';
            }
            case TIPO_ESTADO.RECHAZADA: {
                return 'error';
            }
            default: {
                return 'secondary';
            }
        }

    }

    //LOADING
    const [loading, setLoading] = useState(true)
    const [cotizacionResumen, setResumen] = useState(null)

    //Drawer
    const [openDrawer, setOpenDrawer] = useState(false)
    const closeDrawer = () => {
        setLoading(true)
        setOpenDrawer(false)
    }

    const handleResumen = (id) => {
        getCotizacionById(id)
            .then(response => {
                setResumen(response)
                setLoading(false)
            })
            .catch(error => {
                console.error("error", error)
            })
        setOpenDrawer(true)
    }

    return (
        <Paper className="my_paper" elevation={5}>
            <Grid container>
                <Grid item xs={8}>
                    <Typography>Cotizaciones</Typography>
                </Grid>
                <Grid display={"flex"} justifyContent={"flex-end"} item xs={4}>
                    <Restricted to={PERMISOS.LECTURA}>
                        <Button color="primary" variant="contained" onClick={handleAgregarCotizacion}>+ NUEVA COTIZACIÓN</Button>
                    </Restricted>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Codigo</TableCell>
                                    <TableCell>Elaborado</TableCell>
                                    <TableCell>Nombre</TableCell>
                                    {solicitudSlice && solicitudSlice.tipo !== TIPO_SOLICITUD_FORMACION &&
                                        solicitudSlice.tipo !== TIPO_SOLICITUD_PRODUCTO && <TableCell>Normas</TableCell>}
                                    <TableCell>Estado Propuestas</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {solicitudSlice.loadingSolicitud && (
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255, 255, 255, 0.7)'
                                    }}>
                                        <CircularProgress />
                                    </div>
                                )}
                                {
                                    listaCotizaciones &&
                                    listaCotizacionSort.map((item, index) =>
                                        <TableRow key={"listcotizacion" + index}>
                                            <TableCell>{item.codigo}</TableCell>
                                            <TableCell>{item.creadaPor}</TableCell>
                                            <TableCell>{item.nombre}</TableCell>
                                            {
                                                solicitudSlice && solicitudSlice.tipo !== TIPO_SOLICITUD_FORMACION &&
                                                solicitudSlice.tipo !== TIPO_SOLICITUD_PRODUCTO && (item.normas ?

                                                    <TableCell>{item.normas.map(n => n.norma.nombre).join(', ')}</TableCell>
                                                    :
                                                    <TableCell>Error</TableCell>)
                                            }

                                            <TableCell>
                                                <Box display="flex" sx={{ width: "160px" }} flexDirection="row" flexWrap="wrap" gap={1}>
                                                    {
                                                        item.propuestas &&
                                                        <>
                                                            {
                                                                item.propuestas.map((propuesta, index) =>
                                                                    <Tooltip key={"chips" + index} title={propuesta.estado}>
                                                                        <Chip
                                                                            size="small"
                                                                            label={"Version " + propuesta.version}
                                                                            onClick={() => handleOpenCotizacionPropuesta(item.id, propuesta.id)}
                                                                            color={getColorEstado(propuesta.estado)}
                                                                        />
                                                                    </Tooltip>
                                                                )
                                                            }
                                                        </>
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell>

                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    disabled={item.normas === null && solicitudSlice.tipo !== TIPO_SOLICITUD_PRODUCTO && solicitudSlice.tipo !== TIPO_SOLICITUD_FORMACION}
                                                    onClick={() => handleOpenCotizacion(item.id)}
                                                >ABRIR</Button>


                                            </TableCell>
                                            <TableCell>
                                                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                                                    <Button
                                                        variant='outlined'
                                                        color="primary"
                                                        startIcon={<ContentCopyIcon />}
                                                        disabled={disabledDuplicar(item)}
                                                        onClick={() => handleDuplicate(item.id)}
                                                    >DUPLICAR</Button>
                                                </Restricted>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    startIcon={<SummarizeIcon />}
                                                    onClick={() => handleResumen(item.id)}
                                                >
                                                    RESUMEN
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Drawer
                anchor='left'
                open={openDrawer}
                onClose={closeDrawer}
            >
                {
                    loading ?
                        <LoaderResumenCotizacion />
                        :
                        <Box display="flex" flexDirection="column" style={{ width: "50vw", paddingTop: "100px" }}>
                            <ResumenSolicitud solicitud={solicitudSlice} cotizacion={cotizacionResumen} />
                            <ResumenCotizacion cotizacion={cotizacionResumen} />
                        </Box>
                }
            </Drawer>
        </Paper>
    )
}

function LoaderResumenCotizacion() {
    return (
        <>
            <Box sx={{ marginTop: "15vh" }} display="flex" flexDirection={"row"}>
                <Skeleton variant="rectangular" width={"10vw"} height={60} />
                <Skeleton sx={{ marginLeft: "10px" }} variant="rectangular" width={"10vw"} height={60} />
                <Skeleton sx={{ marginLeft: "10px" }} variant="rectangular" width={"10vw"} height={60} />
                <Skeleton sx={{ marginLeft: "10px" }} variant="rectangular" width={"10vw"} height={60} />
            </Box>
            <Skeleton sx={{ marginTop: "20px" }} variant="rectangular" width={"50vw"} height={150} />
            <Skeleton sx={{ marginTop: "20px" }} variant="rectangular" width={"50vw"} height={150} />
            <Skeleton sx={{ marginTop: "20px" }} variant="rectangular" width={"50vw"} height={150} />
            <Skeleton sx={{ marginTop: "20px" }} variant="rectangular" width={"50vw"} height={150} />
        </>
    )
}


export default PaperListaCotizacion
