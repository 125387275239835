import { TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { getListaOrganizaciones } from 'services/Solicitud'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
 
import { useSelector } from 'react-redux';

const InputOrganizaciones = ({multiple, handleOrganizacion,required}) => {
    const solicitudSlice = useSelector(state => state.solicitud);
    const {filtros} = solicitudSlice

    const [value, setValue] = useState(multiple?[]:filtros.idOrganizacion)
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState([{id:0,nombre:''}])
    const [loading, setLoading] = useState(false)

    const filter = createFilterOptions();

    useEffect(() => {
        const timeout = setTimeout(buscar,200)
        return ()=>{
            clearTimeout(timeout)
        }
    }, [inputValue])

    const buscar = () =>{
        if(inputValue !== ""){
            setLoading(true)
            
            getListaOrganizaciones(inputValue)
            .then((response) =>{
                    setLoading(false)
                    setOptions(response)
            })
            .catch((error) => {
                setLoading(false)
                console.error(error)
            })    
        }
    }

    const handleAutoComplete = (e, newValue) =>{
        if (typeof newValue === 'string') {
            setValue({
              title: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
                id:0,
              nombre: newValue.inputValue,
            });
          } else {
            setValue(newValue);
          }

        if(newValue && newValue.length !== 0){
            if(multiple){
                handleOrganizacion(newValue)
            }else{
                handleOrganizacion(newValue)
            }
        }else{
            if(multiple){
                handleOrganizacion([])
            }else{
                handleOrganizacion([])
            }
        }
    }

    const handleInput = (e, newInputValue) =>{
        setInputValue(newInputValue)
    }

    useEffect(() => {
        if(filtros.idOrganizacion){

        }else{
            setValue(multiple?[]:"")
        }
    }, [filtros.idOrganizacion,multiple])

    const getValue = () => {

        if(multiple){
            if(solicitudSlice.organizaciones)
                return solicitudSlice.organizaciones;
            return [];
        }else{
            return filtros.idOrganizacion;
        }

    }

    return (
        <div>
        <Autocomplete
        autoSelect
        autoHighlight
        disableClearable
        fullWidth
        noOptionsText="Sin resultados"
        value={value}
        multiple={multiple}
        defaultValue={multiple?[]:null}
        getOptionLabel={(option) => {
            if(typeof option === 'string'){
                return option;
            }
            if(option.inputValue){
                return option.inputValue
            }
            return option.nombre
        }}
        inputValue={inputValue}
        loading={loading}
        onInputChange={(e, newInputValue) => handleInput(e, newInputValue)}
        id="autocomplete-org-test"
        onChange={(e, newValue) => handleAutoComplete(e, newValue)}
        filterOptions={(options, params) => {
            const filtered = filter(options, params);
    
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.nombre);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                id:0,
                nombre:inputValue,
                nombreShow: inputValue,
              });
            }
            return filtered;
          }}
        options={options}
        renderInput={
            (params) => (
                <TextField
                required={required ? solicitudSlice.organizaciones === 0:false}
                {...params}
                label="Organizaciones"
                />
            )
        }
        />
    </div>
    )
}

export default InputOrganizaciones
