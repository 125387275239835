import { useContext, useEffect, useState } from 'react';
import { Box, Button, Fab, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { setNuevoTurno, setTurnos } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
 
import AlimentosTurnosInput from './input';
import AlimentosTurnosSelected from './selected';
import { PERMISOS, TIPO_ESTADO } from 'constants/constants';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import { usePermitirEdicion } from 'hooks/usePermitirEdicion';

const classes = {
   add: {
      display: 'flex',
      justifyContent: 'center',
      pt: 1,
      pb: 4
   },
   selected: {
      py: 2
   }
}

const AlimentosTurnos = ({guardarCotizacion})  => {
   const {state, dispatch} = useContext(CotizacionContext);
   const { turnos: turnosCotizacion, json, estadoCotizacion, estadoCarga } = state;

   
   
   // const turnos = turnosCotizacion.length > 0 ? turnosCotizacion : turnosSolicitud;   
   const turnos =  turnosCotizacion;   
  
   const [selected, setSelected] = useState({
      description: '',
      hours: '',
      persons: ''
   });
   const [noEliminar, setNoEliminar] = useState(turnos?.length > 1 ? false : true);
   const hasSelected = selected.description ? true : false;

   const [modoEdicion, setModoEdicion] = useState(false);
   const permitirEdicion = usePermitirEdicion(modoEdicion);

   const updateTurno = (turno, index) => {
      const {descripcion, horarioFin, horarioInicio, personal, selected } = turno;

      const updated = [...turnos];
      updated[index].descripcion = descripcion;
      updated[index].horarioFin = horarioFin;
      updated[index].horarioInicio = horarioInicio;
      updated[index].personal = personal;
      updated[index].selected = selected;

      dispatch(setTurnos(updated));
      getSelected();
   }

   const getSelected = () => {
      if(turnos){
         const selected = turnos?.filter(turno => !turno.selected);

         let description = selected?.map(turno => turno.descripcion || '...');
         description = description.join(' / ');
   
         let hours = selected?.map(turno => `${turno.horarioInicio || '...'} a ${turno.horarioFin || '...'}`);
         hours = hours.join(' / ');
        
         let persons = selected?.map(turno => turno.personal || 0);
         persons = persons.length > 0 && persons.reduce((a,b) => a + b);
         setSelected({
            description,
            hours,
            persons
         });
      }else{
         setSelected({
            description:"-",
            hours:"",
            persons:""
         });
      }
      
     
   }

   const onChange = (turno, index) => {
      updateTurno(turno, index);
   }

   const onAdd = () =>{
      dispatch(setNuevoTurno());
      setNoEliminar(false)
   }

   const handleEditar = (e) => {
      if (modoEdicion) {
         guardarCotizacion(e)
         setModoEdicion(false)
      }
      else {
         setModoEdicion(true)
      }
   }

   useEffect(() => {
      getSelected();
   }, [turnos]);

   return (
      <Paper className="my_paper" elevation={5} sx={{position: "relative" }}>
         { turnos?.map((turno, index) =>
               <AlimentosTurnosInput
                  key={"turno"+index}
                  data={turno}
                  index={index}
                  permiteEdicion={permitirEdicion}
                  many={turnos.length > 1 ? true : false}
                  onChange={(turno, index) => onChange(turno, index)}
                  noEliminar={noEliminar}
               />
            )
         }
         { hasSelected &&
            <Box sx={classes.selected}>
               <AlimentosTurnosSelected 
                  data={selected}
               />
            </Box>
         }
         {
         !permitirEdicion &&
         <Box sx={classes.add}>
           
               <Button 
               variant="contained" 
               startIcon={<AddIcon/>} 
               color="primary"  
               onClick={onAdd}
            >
               Agregar Turno
            </Button>
         
            
         </Box>
         }
         {(estadoCotizacion.permiteEdicion || estadoCotizacion.estado === TIPO_ESTADO.ENVIADA) && json.id !== '' &&
            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
               <Fab
                  color="primary"
                  aria-label="add"
                  sx={{ position: "absolute", right: "-70px", top: "22%" }}
                  onClick={handleEditar}
                  disabled={estadoCarga.guardando}
               >
                  {modoEdicion ?
                     <SaveIcon />
                     :
                     <EditIcon />
                  }
               </Fab>
            </Restricted>
         }
         
      </Paper>  
   )
}

export default AlimentosTurnos;
