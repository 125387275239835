import { Box, Button, Drawer, Fab, Paper, Tooltip } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
 
import { useContext, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DatosCotizacionProducto from "components/DatosCotizacionProducto";
import PaperServicios from "components/PaperServicios/PaperServicios";
import { useParams } from "react-router-dom";
import { getProductos, obtenerPropuestaByIdSilent, setDatosCotizacion, setEstadoCotizacion, setJson, setTab } from "context/cotizacion/actionsCotizacion/ActionsCotizacion";
import { getCotizacionById } from "services/Cotizacion";
import { CotizacionContext } from "context/cotizacion/cotizacionContext";
import TabPropuestaEconomica from "../components/TabPropuestaEconomica/index";
import ObersvacionesTextEditor from "components/ObservacionesTextEditor";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import AlertaGuardado from "components/AlertaGuardado";
import UltimaModificacion from "components/ultimaModificacion";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "redux/slices/globalSlice";

export default function CotizacionProducto() {
    const history = useHistory();
    const { idCotizacion, idPropuesta } = useParams();
    const dispatchRedux = useDispatch()
    const solicitudSlice = useSelector(state => state.solicitud);
    const { dispatch: dispatchCotizacion } = useContext(CotizacionContext);

    const useStyles = makeStyles((theme) => ({
        paper: {
            padding: theme.spacing(3),
            margin: theme.spacing(3),
        },
        fixedButton:{
            margin:0,
            top:"auto",
            left:50,
            bottom:20,
            right:"auto",
            position:"fixed"
        }
       
    }));

    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false)
    const closeDrawer = () => {
        setOpenDrawer(false)
    }

    const navigateToSolicitud = useCallback(() => {
        history.push(`/solicitud/${solicitudSlice.idSolicitud}`);
    }, [solicitudSlice, history]);


    useEffect(() => {
        getCotizacionById(idCotizacion)
            .then(response => {
                let estadoAux = { ...response.estado }
                estadoAux.posiblesTransiciones = estadoAux.posiblesTransiciones.concat(estadoAux.estado)
                estadoAux.motivoRechazo = response.motivoRechazo

                dispatchCotizacion(setEstadoCotizacion(estadoAux))
                const info = {
                    nombre: response.nombre,
                    seguimientos: response.cantidadMantenimientos,
                    codigo: response.codigo,
                    revision: 'D',
                }
                dispatchCotizacion(setDatosCotizacion(info))

                if (response.infoIntegracion) {
                    dispatchCotizacion(setInputIntegracion(response.infoIntegracion))
                }
                dispatchCotizacion(setJson(response))



                if (idPropuesta) {
                    dispatchCotizacion(setTab(response.usaMultisitios ? 2 : 1))
                    obtenerPropuestaByIdSilent(dispatchCotizacion, { id: idPropuesta, params: true });
                    getProductos(dispatchCotizacion);
                } else if (response.propuestas.length > 0) {
                    obtenerPropuestaByIdSilent(dispatchCotizacion, { id: response.propuestas[0].id, params: true });
                    getProductos(dispatchCotizacion);
                }

            })
            .catch(error => {

                dispatchRedux(setError({ estado: true, tipo: error.status, descripcion: error.data?.details ? error.data.details : error.data.message }))
                console.error("ERROR GET COTIZACION BY ID", error)
            })
    }, []);

    return (
        <>
        <Box marginTop={"4vh"} sx={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}} alignItems={"flex-end"} >
            <UltimaModificacion />
            <Button
                variant='outlined' 
                onClick={navigateToSolicitud}
                startIcon={<ArrowBackIosNewIcon />} 
            >
                Volver a cotizaciones
            </Button>
        </Box>
                <DatosCotizacionProducto />
                <PaperServicios
                    handleModal={() => { }}
                    modoEdicion={true}
                    setModoEdicion={() => { }}
                />

            <Paper className="my_paper" sx={{ position: "relative", padding: "2vw" }} elevation={5}>
                <TabPropuestaEconomica />
            </Paper>

            <Fab
                color="primary"
                className={classes.fixedButton}
                aria-label="add"
                onClick={() => setOpenDrawer(true)}
            >
                <Tooltip title="Observaciones">
                    <NoteAltIcon />
                </Tooltip>

            </Fab>

            <Drawer
                anchor='left'
                open={openDrawer}
                onClose={closeDrawer}
            >
                <ObersvacionesTextEditor />
            </Drawer>
            <AlertaGuardado />
        </>
    )
}