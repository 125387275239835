import styled from "@emotion/styled";
import { Autocomplete, darken, lighten, TextField } from "@mui/material";
import { RUTAS } from "constants/routes";
import { setSelectedCep, setOrganizacion, setCantidadEstudiosHaccp, setTurnos } from "redux/slices/solicitudSlice";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { TIPO_SOLICITUD_ALIMENTOS } from 'constants/constants';
import { useDispatch, useSelector } from "react-redux";

const classes = {
    input: {
        backgroundColor: 'background.paper'
    },
    root: {
       width: 480,
       mb: 3
    }
}

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});


function CepSelector() {
    const history = useHistory();
    const solicitudSlice = useSelector(state => state.solicitud);
    const dispatchSolicitud = useDispatch();
    const globalSlice = useSelector(state => state.global);
    let { cepsDisponibles } = solicitudSlice;

    const onClickCep = (event, value) => {
        if(value !== undefined){
            const isFood = value.tipo === TIPO_SOLICITUD_ALIMENTOS ? true : false;

            dispatchSolicitud(setSelectedCep(value));
            dispatchSolicitud(setOrganizacion(value.organizaciones));

            if (isFood) {
                dispatchSolicitud(setCantidadEstudiosHaccp(value.cantidadEsutiosHACCP));
                dispatchSolicitud(setTurnos(value.turnos));
                history.push(RUTAS.ALIMENTOS_SOLICITUD_NUEVA);
            } else {
                history.push(RUTAS.NUEVASOLICITUD);
            }

        }
    }

    if(cepsDisponibles === null || cepsDisponibles.lenght === 0) return null;

    const emailUsuario = globalSlice.usuario.user_id;

    cepsDisponibles = cepsDisponibles.sort((a, b) => {
        if(a.emailUsuarioAsignado === emailUsuario) return -1;
        if(b.emailUsuarioAsignado === emailUsuario) return 0;
        return 1;
    });

    const mapToAutocompleteFormat = (ceps) => {
        return ceps.map(c => ({
            label: c.numero + " - " + c.organizaciones[0].nombre,
            shortEmail: c.emailUsuarioAsignado.split("@")[0],
            ...c
        }));
    }

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            groupBy={(o) => o.shortEmail}
            getOptionLabel={(o) => o.label}
            onChange={onClickCep}
            options={mapToAutocompleteFormat(cepsDisponibles)}
            sx={classes.root}
            renderInput={(params) => <TextField {...params} label="Solicitudes CEP" sx={classes.input} />}
            renderGroup={(params) => (
                <li>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            )}
        />
    );
}

export default CepSelector;