import { useEffect, useState } from "react";

const { Autocomplete, TextField } = require("@mui/material");
const { useSelector, useDispatch } = require("react-redux");
const { fetchGruposEconomicos } = require("redux/slices/grupoEconomicoSlice");


export default function InputGrupoEconomico({onChange, grupoEconomico}) {

    const [inputValue, setInputValue] = useState('');

    const dispatch = useDispatch();
    const { gruposEconomicos, loading, error } = useSelector(state => state.grupoEconomico);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (inputValue.length >= 2) {
                dispatch(fetchGruposEconomicos(inputValue));
            }
        }, 300);

        return () => clearTimeout(handler);

    }, [inputValue]);

    useEffect(() => {

        if(grupoEconomico && grupoEconomico.nombre !== inputValue) {
            setInputValue(grupoEconomico.nombre);
        }

    }, [grupoEconomico]);


    const getOptions = () => {

        if(inputValue.length < 2) return [];

        return gruposEconomicos.map(grupo => {
            return {
                id: grupo.id,
                label: grupo.nombre
            }
        });
    }

    return (
        <Autocomplete
            id="input-grupo-economico"
            options={getOptions()}
            freeSolo
            loading={loading}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
            }}
            value={grupoEconomico?.nombre}
            onChange={(_, newValue) => {

                if(newValue === null) {
                    return;
                }

                if(typeof newValue === 'string') {
                    newValue = {id: null, label: newValue};
                }

                onChange && onChange({id: newValue.id, nombre: newValue.label});
            }}
            renderInput={(params) => (
                <TextField
                    value={inputValue}
                    {...params}
                    label="Buscar..."
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <span>Cargando...</span> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    )
}
