import { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import { useLocation } from 'react-router';
import { useContext } from 'react';
 
import { useSelector } from 'react-redux';

const classes = {
   root: {
      ml: 4,
      px: 2,
      fontWeight: 'fontWeightMedium',
      textTransform: 'uppercase'
   }
}

const Section = () => {
   const location = useLocation();
   const [section, setSection] = useState(null);
   const solicitudSlice = useSelector(state => state.solicitud);
   const getSection = () => {
      
      setSection(solicitudSlice.tipo);
   }

   useEffect(() => {
      getSection();
   }, [solicitudSlice]);
   if(section)
      return <Chip label={section} color="primary" variant="outlined" sx={classes.root} />;
   else
      return null;
}

export default Section;