import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Button, Fade, Menu, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { RUTAS } from "constants/routes";
 
import { setPersonalEfectivo, setOrganizacion, setSelectedCep } from 'redux/slices/solicitudSlice';
import { TIPO_SOLICITUD_ALIMENTOS, TIPO_SOLICITUD_FORMACION, TIPO_SOLICITUD_GESTION, TIPO_SOLICITUD_PRODUCTO } from 'constants/constants';
import usePermisos from 'hooks/usePermisos';
import { useDispatch } from 'react-redux';


const classes = {
   button: {
      borderRadius: 1
   },
   root: {
      ml: 'auto'
   }
}

const MenuSolicitud = () => {
   const history = useHistory();
    const dispatchSolicitud = useDispatch();
   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);
   
   const roles = usePermisos();
   
   const onClick = (event) => {
      setAnchorEl(event.currentTarget);
   };
   
   const onGoTo = (event) => {

      setAnchorEl(null);
      let { route, type } = event.currentTarget.dataset;

      dispatchSolicitud(setSelectedCep(null));
      dispatchSolicitud(setPersonalEfectivo(""));
      dispatchSolicitud(setOrganizacion([]));

      route = route + "?tipo=" + type;
      history.push(route)
    };

   const onClose = () => {
      setAnchorEl(null);
   };

   return (
      <Box sx={classes.root}>
         <Button
            id="fade-button"
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={onClick}
            sx={classes.button}
         >
            Nueva Solicitud
         </Button>
         <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            TransitionComponent={Fade}
         >
            {roles.includes("RDAEscritorAlimentos") && <MenuItem data-type={TIPO_SOLICITUD_ALIMENTOS} data-route={RUTAS.NUEVASOLICITUD} onClick={onGoTo}>Alimentos</MenuItem>}
            {roles.includes("RDAEscritor") && <MenuItem data-type={TIPO_SOLICITUD_GESTION} data-route={RUTAS.NUEVASOLICITUD} onClick={onGoTo}>Gestión</MenuItem>}
           {roles.includes("RDAEscritorFormacion") && <MenuItem data-type={TIPO_SOLICITUD_FORMACION} data-route={RUTAS.NUEVASOLICITUD} onClick={onGoTo}>Formación</MenuItem>}
           {roles.includes("RDAEscritorProducto") && <MenuItem data-type={TIPO_SOLICITUD_PRODUCTO} data-route={RUTAS.NUEVASOLICITUD} onClick={onGoTo}>Producto</MenuItem>}
         </Menu>
      </Box>
   );
}

export default MenuSolicitud;