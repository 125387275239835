import React, { useContext } from 'react'
import {Grid, Typography, TextField, Button} from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
 
 
import { useDispatch, useSelector } from 'react-redux';

const InputSolicitud = () => {

    const globalSlice = useSelector(state => state.global)
    const {usuario} = globalSlice
    const nombreUsuario = usuario.user_claims.find(u => u.typ === 'name').val

    //Context
    const solicitudSlice = useSelector(state => state.solicitud);
    const dispatchSolicitud = useDispatch();
    const {numero, estado} = solicitudSlice;

    const anioLocal = new Date().getFullYear()

    const handleSetNumero = (e) =>{
        dispatchSolicitud({
            type: 'setNumero',
            payload: e.target.value
        });
    }

    return (
       <>
            <Grid item  xs={3}>
                <Typography  variant="h6">Solicitud de Cotización</Typography>
            </Grid>
            <Grid item xs={2}><TextField variant="outlined" type='number'  disabled label='Número' value={numero} onChange={handleSetNumero}></TextField></Grid>
            <Grid item xs={2}><TextField variant="outlined" type='number'  disabled label='Año' value={anioLocal}></TextField></Grid>
            <Grid item xs={2}><TextField variant="outlined" type='text' disabled label='Elaborado' value={nombreUsuario} ></TextField></Grid>
            <Grid item xs={3}>
                <Button fullWidth disabled variant="outlined" startIcon={<FiberManualRecordIcon/>}>
                {estado}
                </Button>
            </Grid>
        </>
    )
}

export default InputSolicitud
