import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch,  } from 'react-router-dom';
// Components
import Home from 'pages/Home';
import Documento from 'pages/Documento'
import NotFound from 'pages/NotFound';
import { RUTAS } from 'constants/routes';
import ModalError from 'components/modalError';
import { CotizacionProvider } from 'context/cotizacion/cotizacionContext';
import Test from 'components/test';
import EnvioDocumento from 'pages/EnvioDocumento';
import Solicitud from 'pages/Solicitud';
import NuevaSolicitudBasePage from 'pages/common/NuevaSolicitudBasePage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { LOGINURL } from 'config/config';
import { useGlobalData } from '../hooks/useGlobalData';
import { useSelector } from 'react-redux';

const ResolveRedirect = () => {

   const router = useHistory();

   useEffect(() => {
      const beforeRedirect = localStorage.getItem("beforeRedirect");
      if(beforeRedirect){
         router.push(beforeRedirect); 
      }else{
         router.push(RUTAS.HOME);
      }
   },[]);

   return (
      <div>
         <h1>Redirecting...</h1>
      </div>
   )

}

const Routes = () => {


   useGlobalData();

   const globalSlice = useSelector(state => state.global)
   const { error } = globalSlice

   const renderAuthenticatedRoutes = () => {
      return (
            <CotizacionProvider>
      <Router basename={"/"}>
         <Switch>
            <Route exact path={RUTAS.AZURE_REDIRECT} component={ ResolveRedirect } />
            <Route exact path={RUTAS.HOME} component={ Home } />
            <Route exact path={RUTAS.DOCUMENTO} component={Documento}/>
            <Route exact path={RUTAS.ENVIODOCUMENTO} component={EnvioDocumento}/>
            <Route exact path={RUTAS.HOME} component={ Home } />
            <Route exact path={RUTAS.NUEVASOLICITUD} component={ NuevaSolicitudBasePage }/>
            <Route path={RUTAS.SOLICITUD} component={ Solicitud }/>
            <Route path={"/test"} component={ Test}/>

            {/* Alimentos */}
            {/* <Route exact path={RUTAS.ALIMENTOS_SOLICITUD_NUEVA} component={ AlimentosSolicitud }/>
            <Route exact path={[ RUTAS.ALIMENTOS_SOLICITUD, RUTAS.ALIMENTOS_COTIZACION_NUEVA, RUTAS.ALIMENTOS_COTIZACION ]} component={ AlimentosCotizacion }/> */}

            <Route path="*" component={ NotFound } />
         </Switch>
         <ModalError isOpened={error.estado}/>
      </Router>
      </CotizacionProvider>
      );
   }


   const renderRedirect = () => {
      let host = window.location.protocol + "//" + window.location.host;
      const path = window.location.pathname;
      const queryParams = window.location.search;
      localStorage.setItem("beforeRedirect", path + queryParams);
      host = host.replace('localhost', "127.0.0.1")
      window.location.replace(LOGINURL + "?post_login_redirect_uri=" + host + "/auth/redirect");
      return null;
   }
   
   
   return (
      globalSlice.error && globalSlice.error.statusCode === 401 ? renderRedirect() : renderAuthenticatedRoutes()
   );
}

export default Routes;
