import React, { useEffect } from 'react'
import PaperListaCotizacion from 'components/papercotizacionesList';
import Page from 'layouts/Page';
import DetailSolicitud from 'components/DetailSolicitud';
import { RUTAS } from 'constants/routes';
import { Route, Switch, useParams } from 'react-router-dom';
 
import { getSolicitudById } from 'services/Solicitud';
import { fetchSolicitudById, setCEP, setSolicitud } from 'redux/slices/solicitudSlice';
import NuevaCotizacionBasePage from './common/NuevaCotizacionBasePage';
import Documento from './Documento';
import CotizacionBasePage from './common/CotizacionBasePage';
import { useDispatch, useSelector } from 'react-redux';


const Solicitud = () => {

    const { idSolicitud: idSolicitudParam } = useParams();
    const { idSolicitud } = useSelector(state => state.solicitud);
    const dispatchSolicitud = useDispatch();

    useEffect(() => {
        if (idSolicitudParam && idSolicitud !== idSolicitudParam) {
            dispatchSolicitud(fetchSolicitudById(idSolicitudParam));
        }
    }, [idSolicitudParam]);

    return (
        <>
            <Page className='home'>
                <DetailSolicitud />
                    <Switch>
                    <Route exact path={RUTAS.SOLICITUD} component={PaperListaCotizacion}/>
                    <Route path={RUTAS.NUEVACOTIZACION} component={NuevaCotizacionBasePage}/>
                    <Route exact path={RUTAS.DOCUMENTO} component={Documento}/>
                    <Route path={RUTAS.PROPUESTAECONOMICA} component={CotizacionBasePage}/>
                    <Route path={[RUTAS.COTIZACION]} component={CotizacionBasePage}/>
                    </Switch> 
            </Page>
        </>
    )
}

export default Solicitud;
