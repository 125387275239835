import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
 
import { useContext, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import DatosCotizacionProducto from "components/DatosCotizacionProducto";
import PaperServicios from "components/PaperServicios/PaperServicios";
import MotivosCotizacionSelect from "components/MotivosCotizacionSelect/MotivosCotizacionSelect";

import SaveIcon from '@mui/icons-material/Save';
import { postCotizaciones } from "services/Cotizacion";
import { TIPO_SOLICITUD_FORMACION } from "constants/constants";
import { useSelector } from "react-redux";

export default function NuevaCotizacionFormacion() {
    const history = useHistory();
    
    const solicitudSlice = useSelector(state => state.solicitud);
    
    const [nuevaCotizacion, setNuevaCotizacion] = useState({});

    const navigateToSolicitud = useCallback(() => {
        history.push(`/solicitud/${solicitudSlice.idSolicitud}`);
    }, [solicitudSlice, history]);

    const navigateToCotizacion = useCallback((idCotizacion) => {
        history.push(`/solicitud/${solicitudSlice.idSolicitud}/cotizacion/${idCotizacion}`);
    }, [solicitudSlice, history]);

    const save = async (event) => {

        const tipoCurso = {
            nombre: nuevaCotizacion.tipoCurso,
            id: nuevaCotizacion.tipoCurso === "ABIERTO" ? 1 : 2
        }

        const body = {
            idSolicitud: solicitudSlice.idSolicitud,
            nombre: nuevaCotizacion.nombre,
            tipoCurso: tipoCurso,
            tipo: TIPO_SOLICITUD_FORMACION
        };

        await postCotizaciones(body).then(result => {
            navigateToCotizacion(result.id)
        });

    }

    const onNombreChange = (event) => {
        setNuevaCotizacion({ ...nuevaCotizacion, nombre: event.target.value });
    }

    const onTipoCursoChange = (event) => {
        setNuevaCotizacion({ ...nuevaCotizacion, tipoCurso: event.target.value });
    }


    return (
        <>
            <Box marginTop={"4vh"} sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }} alignItems={"flex-end"} >
                <Button
                    variant='outlined'
                    onClick={navigateToSolicitud}
                    startIcon={<ArrowBackIosNewIcon />} >Volver a cotizaciones</Button>
            </Box>
            <Paper className="my_paper" elevation={5} sx={{ position: "relative", padding: "2vw" }}>
                <Grid>
                    <Typography variant="h6">Nueva cotización</Typography>
                    <TextField
                        style={{ width: "30vw", marginTop: "2vh" }}
                        label="Nombre cotización"
                        type="text"
                        value={nuevaCotizacion.nombre}
                        onChange={onNombreChange}
                        required />

                    <FormControl sx={{ marginTop: "2vh", marginLeft: "2%" }}>

                        <InputLabel id='label-tipo'>Tipo</InputLabel>
                        <Select
                            value={nuevaCotizacion.tipo}
                            onChange={onTipoCursoChange}
                            labelId='label-tipo-curso'
                            label="Tipo"
                            name="tipo"
                            style={{ width: "20vw" }}
                        >
                            <MenuItem value={"INCOMPANY"}>Incompany</MenuItem>
                            <MenuItem value={"ABIERTO"}>Abierto</MenuItem>
                        </Select>

                    </FormControl>
                </Grid>
                
                <Box sx={{marginTop: "2vh", width: "100%", display: "flex", justifyContent: "flex-end"}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={save}
                        startIcon={<SaveIcon />}
                    >Guardar</Button>
                </Box>

            </Paper>
        </>
    )
}