import { Box, Button } from "@mui/material";
import FormCotizacion from "components/FormCotizacion";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
 
import { useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function NuevaCotizacionGestion() {
    const history = useHistory();
    
    const solicitudSlice = useSelector(state => state.solicitud);

    const navigateToSolicitud = useCallback(() => {
        history.push(`/solicitud/${solicitudSlice.idSolicitud}`);
    },[solicitudSlice, history]);

    return (
        <>
            <Box marginTop={"4vh"} sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }} alignItems={"flex-end"} >
                <Button
                    variant='outlined'
                    onClick={navigateToSolicitud}
                    startIcon={<ArrowBackIosNewIcon />} >Volver a cotizaciones</Button>
            </Box>
            <FormCotizacion/>
        </>
    )
}