import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDataAsync } from 'services/httpRequest';

const initialState = {
    loading: false,
    error: null,
    gruposEconomicos: []
};

export const fetchGruposEconomicos = createAsyncThunk('grupoEconomico/fetch', async (nombre) => {
    try {
        return (await getDataAsync(`GruposEconomicos/search?nombre=${nombre}`)).data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
});


export const grupoEconomicoSlice = createSlice({
    name: 'grupoEconomico',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {

        builder
            .addCase(fetchGruposEconomicos.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchGruposEconomicos.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.gruposEconomicos = action.payload;
            })
            .addCase(fetchGruposEconomicos.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

    },
});

export default grupoEconomicoSlice.reducer;