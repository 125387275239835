import axios from 'axios';
import { searchOrganizaciones } from 'services/Organizacion/organizacionService';
import { getDataAsync, postDataAsync, putDataAsync } from 'services/httpRequest';

export const getListCodigoIAF = async () => {
   const response = await getDataAsync('IAFs');
   if (response.status === 200 || response.status === 201) { 
      return response.data
   } else {
      throw response
   }
}

export const postSolicitud = async (data) => {
   const response = await postDataAsync('Solicitudes/',data);
   if (response.status === 200 || response.status === 201) { 
      return response.data
   } else {
      throw response
   }
}

export const updateSolicitud = async (data) => {
   const response = await putDataAsync('Solicitudes/',data);
   if (response.status === 200 || response.status === 201) { 
      return response.data
   } else {
      throw response
   }
}


export const getSolicitudById = async(data) => {
   const response = await getDataAsync('Solicitudes/' + data)
   if (response.status === 200 || response.status === 201) { 
      return response.data
   } else {
      throw response
   }
}

export const getListaSolicitudes = async () => {
   const response = await getDataAsync('Solicitudes')
   if (response.status === 200 || response.status === 201) { 
      return response.data
   } else {
      throw response
   }
}

export const getListaOrganizaciones = async (param) => {
   const response = await getDataAsync('Organizaciones?query='+param)
   if(response.status === 200 || response.status === 201)
   {
      return response.data
   }
   else{
      throw response
   }
}

export const getSolicitudesFiltros = async (filtros) => {
   const response = await postDataAsync('Buscador', filtros)
   if(response.status === 200 || response.status === 201)
   {
      return response.data
   }
   else{
      throw response
   }
}

export const getSolicitudesCep = async () => {
   const response = await getDataAsync('cep')
   if(response.status === 200 || response.status === 201)
   {
      return response.data
   }
   else{
      throw response
   }
}

export const postDuplicarSolicitud = async (id) => {
   const response = await postDataAsync('Solicitudes/'+id+'/duplicar')
   if(response.status === 200 || response.status === 201)
   { 
      return response.data
   }
   else{
      throw response
   }
}

export const getResumenPropuestasByIdSolicitud = async (idSolicitud, soloAceptadas) => {
   const response = await getDataAsync('Solicitudes/'+idSolicitud+'/propuestas?soloAceptadas=' + soloAceptadas)
   if(response.status === 200 || response.status === 201)
   {
      return response.data
   }
   else{
      throw response
   }
}