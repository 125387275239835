import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
 
import React from 'react'
import { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { classes } from 'components/alimentos/datosCotizacion/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useState } from 'react';
import DiasAuditorUsuario from 'components/diasAuditorUsuario';
import { setNormaMotivo, updateNorma } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
 
import { useDispatch, useSelector } from 'react-redux';
const useStyles = makeStyles(theme => ({
    Accordion: {
        padding: 0,
        boxShadow: `0px 0px 0px 0px`,
        border: `1px solid black`,
        borderRadius: `5px`
      },
  }));

function AccordionNormaAlimentos({norma, index, idGrupo, idSitio}) {
    const solicitudSlice = useSelector(state => state.solicitud);
    const { turnos: turnosSolicitud } = solicitudSlice;

    const {state, dispatch} = useContext(CotizacionContext);
    const { turnos: turnosCotizacion, json } = state;

    const dispatchRedux = useDispatch()
    const sitioNorma = norma
    
    const classes = useStyles();

    const jerarquia = norma.cotizacionNorma.norma.jerarquiaAlimentos;
    const renderTiempoEstudios = () => {
        if(sitioNorma.tiempoAdicionalEstudiosHACCP){
            return(     <TextField
                label="TH. Tiempo Adicional por HACCP"
                type='number'
                id="as1"
                variant='outlined'
                value={sitioNorma.tiempoAdicionalEstudiosHACCP}
                />)
        }else{
            if(sitioNorma.tiempoAdicionalEstudiosHACCP === 0){
                return(<TextField
                    label="TH. Tiempo Adicional por HACCP"
                    type='number'
                    id="as1"
                    variant='outlined'
                    value={sitioNorma.tiempoAdicionalEstudiosHACCP}
                    />)
            }
        }
    }
    
    const renderTiempoAdicionalEmpleados = () => {
        if(sitioNorma.tiempoAdicionalEmpleadosHACCP){
            return(
                <TextField
                label="FTE. Tiempo de empleados"
                type='number'
                id="as3"
                variant='outlined'
                value={sitioNorma.tiempoAdicionalEmpleadosHACCP?sitioNorma.tiempoAdicionalEmpleadosHACCP:0}
                />)
        }else{
            if(sitioNorma.tiempoAdicionalEmpleadosHACCP === 0){
                return( <TextField
                    label="FTE. Tiempo de empleados"
                    type='number'
                    id="as3"
                    variant='outlined'
                    value={sitioNorma.tiempoAdicionalEmpleadosHACCP?sitioNorma.tiempoAdicionalEmpleadosHACCP:0}
                    />)
            }
        }
    }

    const renderTiempoFaltaSistema = () => {
        var jerarquia = norma.cotizacionNorma.norma.jerarquiaAlimentos
        if(jerarquia === 3){
            return(null)
        }
        else{
            return(
                <FormControl sx={{width:"200px"}}>
                    <InputLabel id="tms-label">TMS. Tiempo por falta de sistema</InputLabel>
                    <Select
                    labelId='tms-label'
                    label="TMS. Tiempo por falta de sistema"
                    value={tms}
                    onChange={handleTMS}
                    >
                        {
                            listaFaltaSistema.map((value, index) =>
                            <MenuItem key={"item"+index} value={value}>{value}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            )
        }
    }
    const listaFaltaSistema = [0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
    const [tms, setTMS] = useState(norma.tiempoFaltaSistema)
    const handleTMS = (e) => {
        setTMS(e.target.value)
        let normaAux = {...norma}
        normaAux.tiempoFaltaSistema = e.target.value;
        updateNorma(dispatch, json.id, idGrupo, idSitio, normaAux)
    }

  return (
    <Box>
        <Accordion 
        className={classes.Accordion} 
        sx={{marginTop:"10px"}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />} 
            >
                <Box display="flex" flexDirection="row" sx={{width:"100%"}} justifyContent="space-between">
                    <Box display="flex" gap={1}>
                        <Typography variant='body1' fontWeight={index===0?"bold":""}>{norma.cotizacionNorma.norma.nombre}</Typography>
                    </Box>
                    <Box>{norma.cotizacionNorma.motivo}</Box>
                </Box>
                
            </AccordionSummary>
            <AccordionDetails>
            <Box display="flex" sx={{paddingTop:"20px", paddingBottom:"20px", backgroundColor:"", width:"100%"}}>
                <Box display="flex" sx={{backgroundColor:"", width:"100%"}} justifyContent="space-between" flexDirection="column" >
                    <Box display="flex" sx={{width:"100%", marginBottom:"30px"}}  justifyContent="space-between" gap={1}>
                    {
                        jerarquia !==3 &&
                        <TextField
                            label="TD. Tiempo Base"
                            type='number'
                            id="as"
                            variant='outlined'
                            value={sitioNorma.tiempoBase?sitioNorma.tiempoBase:0}
                            />
                    }
                    {
                        sitioNorma.tiempoFssc &&
                        <TextField
                        label="Adicional FSSC"
                        type='number'
                        id="as1"
                        variant='outlined'
                        value={sitioNorma.tiempoFssc?sitioNorma.tiempoFssc:0}
                        />
                    }
                    {
                        renderTiempoEstudios()   
                    }
                    {
                        renderTiempoFaltaSistema()
                    }
                    {
                        renderTiempoAdicionalEmpleados()
                    }
                    {/* {
                        sitioNorma.tiempoAdicionalSitio &&
                        <TextField
                        label="TAS. Adicional por sitio a visitar"
                        disabled
                        variant='outlined'
                        type='number'
                        id="as45"></TextField>
                    } */}
                
                    </Box>
                    
                    <Box display="flex" sx={{width:"100%"}} flexDirection="row">
                          <DiasAuditorUsuario norma={norma} idGrupo={idGrupo} idSitio={idSitio}></DiasAuditorUsuario>
                    </Box>
                </Box>
            </Box>
            </AccordionDetails>
        </Accordion>
    </Box>
  )
}

export default AccordionNormaAlimentos