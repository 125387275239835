import { useEffect, useState } from 'react';
import { Box } from '@mui/system'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Autocomplete, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import { ModalEditarOrganizacion } from 'components/ModalEditarOrganizacion/ModalEditarOrganizacion';
import InputOrganizaciones from 'components/InputOrganizaciones/solicitud';
import { useDispatch, useSelector } from 'react-redux';
import { editarSolicitud } from 'redux/slices/editarSolicitudSlice';
import InputGrupoEconomico from 'components/InputGrupoEconomico';
import { setOrganizacion, setPersonalEfectivo } from 'redux/slices/solicitudSlice';


export default function EditarSolicitudModal({ isOpened, onClose, solicitud }) {

    const { inProgress, error } = useSelector(state => state.editarSolicitud);

    const [state, setState] = useState({
        personalEfectivo: solicitud.personalEfectivo,
        organizaciones: solicitud.organizaciones,
        grupoEconomico : solicitud.grupoEconomico
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (isOpened) {
            setState({
                personalEfectivo: solicitud.personalEfectivo,
                organizaciones: solicitud.organizaciones,
                grupoEconomico : solicitud.grupoEconomico
            });
        }
    }, [solicitud, isOpened]);

    const [empresaEditando, setEmpresaEditando] = useState(null);
    const [nuevaEmpresa, setNuevaEmpresa] = useState('');

    const [nuevaOrganizacion, setNuevaOrganizacion] = useState(null);

    const handleSave = () => {
        let bodySolicitud = {
            id: solicitud.idSolicitud,
            cantidadPersonal: state.personalEfectivo,
            tipo: solicitud.tipo,
            organizaciones: state.organizaciones,
            grupoEconomico: state.grupoEconomico
        };

        dispatch(editarSolicitud(bodySolicitud))
        .then((response) => {
            console.log(response);

            dispatch(setPersonalEfectivo(response.payload.cantidadPersonal));
            dispatch(setOrganizacion(response.payload.organizaciones));
            onClose(); 

        });
    };

    const handleEditarEmpresa = (empresa) => {
        setEmpresaEditando(empresa);
    };

    const handleEditarGrupoEconomico = (grupoEconomico) => {
        setState(prev => ({ ...prev, grupoEconomico: grupoEconomico }));
    }

    const handleEliminarEmpresa = (id) => {
        setState(prev => ({ ...prev, organizaciones: prev.organizaciones.filter(e => e.id !== id) }));
    };

    const handleAgregarEmpresa = () => {

        const nuevaOrg = {
            idCore: nuevaOrganizacion.id,
            nombre: nuevaOrganizacion.nombre,
            cuit: nuevaOrganizacion.cuit
        }

        setState(prev => ({ ...prev, organizaciones: [...prev.organizaciones, nuevaOrg] }));
        setNuevaOrganizacion("");

    };

    const handlePersonalEfectivoChange = (e) => {
        setState(prev => ({ ...prev, personalEfectivo: e.target.value }));
    }

    const handleGuardarEmpresa = (empresaEditada) => {
        let organizacion = { ...state.organizaciones.find(e => e.idCore === empresaEditada.id) };

        if (organizacion) {
            organizacion.nombre = empresaEditada.razonSocial;
        }
        setEmpresaEditando(null);
        let restoOrganizaciones = state.organizaciones.filter(e => e.idCore !== empresaEditada.id);
        setState(prev => ({ ...prev, organizaciones: [...restoOrganizaciones, organizacion] }));
    }

    

    return (
        <><Modal open={isOpened} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "50vw",
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4
            }}>
                <Typography variant="h6" component="h2" gutterBottom>
                    Editar solicitud
                </Typography>
                <TextField
                    label="Personal Efectivo"
                    type="number"
                    value={state.personalEfectivo}
                    onChange={handlePersonalEfectivoChange}
                    fullWidth
                    margin="normal"
                />
                <Typography sx={{ marginTop: "50px" }} variant="h6" gutterBottom>
                    Organizaciones
                </Typography>
                <List>
                    {state.organizaciones.map((empresa) => (
                        <ListItem
                            key={empresa.id}
                            secondaryAction={
                                <>
                                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditarEmpresa(empresa)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleEliminarEmpresa(empresa.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                            }
                        >
                            <ListItemText primary={empresa.nombre} />
                        </ListItem>
                    ))}
                </List>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <InputOrganizaciones
                        value={nuevaOrganizacion}
                        handleOrganizacion={setNuevaOrganizacion}
                        multiple={false} />
                    <IconButton onClick={handleAgregarEmpresa}>
                        <AddIcon />
                    </IconButton>
                </Box>
                <Typography sx={{marginTop: "50px"}} variant="h6" gutterBottom>
                    Grupo Economico
                </Typography>
                
                <InputGrupoEconomico grupoEconomico={state.grupoEconomico} onChange={handleEditarGrupoEconomico}  />

                <Box sx={{ mt: 10, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} sx={{ mr: 1 }}>Cancelar</Button>
                    {!inProgress && <Button onClick={handleSave} variant="contained">Guardar</Button>}
                    {inProgress && <CircularProgress />}
                </Box>
            </Box>

        </Modal>
            <ModalEditarOrganizacion
                id={empresaEditando?.idCore}
                isOpened={!!empresaEditando}
                onClose={() => setEmpresaEditando(null)}
                onSave={handleGuardarEmpresa}
            />
        </>
    );
}